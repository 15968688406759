/** @jsx jsx */

import {css, jsx} from "@emotion/core"

const loaderStyle = css`
    width: 100%;
    height: 100%;
    display: flex;
    svg {
        margin: auto;
    }
`;

function Loader() {
    return <div css={loaderStyle}><svg width="80px" height="80px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
                                       preserveAspectRatio="xMidYMid" className="lds-dual-ring">
        <circle cx="50" cy="50" fill="none"
                strokeLinecap="round" r="27" strokeWidth="7" stroke="#4eb8cc"
                strokeDasharray="42.411500823462205 42.411500823462205" transform="rotate(60 50 50)">
            <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50"
                              keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"/>
        </circle>
    </svg></div>
}

export default Loader;