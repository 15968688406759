/** @jsx jsx */

import Player from '@vimeo/player';
import React from 'react';
import {jsx, css} from "@emotion/core";
import {GlobalIcons, GlobalStyles} from "../styles/globalstyles";
import Loader from "./loader";
import {navigate} from "gatsby";
import Layout from "./layout";
import SEO from "./seo";

class VideoPlayer extends React.Component {

    playerRef;
    player;

    constructor(props) {
        super(props);

        this.state = {
            showButton : true,
            loading: true,
        };

        this.playerRef = React.createRef();
    }

    componentDidMount() {
        this.setupPlayer();

        this.player.ready().then( () => {
            this.setState({
                loading: false
            });
        });
    }

    setupPlayer = () => {

        this.player = new Player('vimeo-player', {
            id: this.props.pageContext.vimeoVideoId,
            color: this.props.pageContext.color,
            title: false,
            portrait: false,
            byline: false,
        });

        this.player.on('play', this.playerEventPlaying);
        this.player.on('pause', this.playerEventPaused);
        this.player.on('ended', this.playerEventEnded);
    };

    render() {

        const container = css`
              position: absolute;
              display: flex;
              border-width: 1.5625rem;
              border-style: solid;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              background-color: white;
              box-sizing: border-box;
              padding-left: calc(50vw - 600px);
              padding-right: calc(50vw - 600px);
              z-index: 9;
              border-color: ${this.props.pageContext.color};
              @media screen and (max-width: 1024px) {
                  position: unset;
                  width: 100%;
                  min-height: 80vh;
              }
        `;

        const playButtonContainer = css`
            display: flex;
            width: 100%;
        `;

        const playButtonBase = css`
            background-size: cover;
            width: 18rem;
            height: 18rem;
            border: 0;
            z-index: 2;
            cursor: pointer;
        `;

        const playButton = css`
            ${GlobalIcons.playIcon}
            ${playButtonBase}
            margin: ${ this.props.pageContext.vimeoDGSVideoId !== "" ? `auto 0.5rem auto auto` : `auto` };
            max-width: 40vw;
            max-height: 40vw;
        `;

        const playDGSButton = css`
            ${GlobalIcons.playDGSIcon}
            ${playButtonBase}
            margin: auto auto auto 0.5rem;
            max-width: 40vw;
            max-height: 40vw;
        `;

        const vimeoPlayer = css`
            position: absolute;
            height: 100%;
            padding-right: 100%;
            overflow: hidden;
            z-index: 1;
            iframe {
                position: absolute;
                top: 0px;
                height: 100%;
                left: 0px;
                width: 100%;
            }
        `;

        return <Layout><div css={container}>
            <SEO title={"Ein Film über "+this.props.pageContext.bausteinName} />
            <div css={GlobalStyles.overlayInnerContainer} >
                <button aria-label={"Zur&uuml;ck zum Baustein"} css={[GlobalStyles.buttonCloseStyle, css`z-index: 2;`]} onClick={this.handleClose}/>
                { this.state.loading && <Loader/> }
                {this.state.showButton && !this.state.loading &&
                <div css={playButtonContainer}>
                    <button aria-label={"Video abspielen Button"}  css={playButton} onClick={this.handleClickPlay} />
                    { this.props.pageContext.vimeoDGSVideoId !== "" && <button aria-label={"DGS Video abspielen Button"}  css={playDGSButton} onClick={this.handleClickPlayDGS} /> }
                </div>
                }
                <div ref={this.playerRef} id={"vimeo-player"} css={vimeoPlayer} />
            </div>
        </div></Layout>
    }

    handleClose = () => {
        this.player.destroy();
        navigate("/baustein/"+this.props.pageContext.bausteinSlug);
    }

    handleClickPlay = () => {
        this.playVideoId(parseInt(this.props.pageContext.vimeoVideoId));
    }

    handleClickPlayDGS = () => {
        this.playVideoId(parseInt(this.props.pageContext.vimeoDGSVideoId));
    }

    playVideoId = (videoId) => {
        this.player.getVideoId().then( (id) => {
            if(id === videoId) {
                this.player.play();
            }
            else {
                this.player.loadVideo(videoId).then( () => {
                    this.player.play();
                });
            }
        });
    }

    playerEventPlaying = () => {
        this.setState({
            showButton: false
        });
    }

    playerEventPaused = () => {
        this.setState({
            showButton: true
        });
    }

    playerEventEnded = () => {
        this.player.setCurrentTime(0);
    }
}

export default VideoPlayer;